import { isProductionEnvironment } from 'env';

export const WEIGHTLOSS_INITIAL_FORM_URL = isProductionEnvironment
  ? 'https://secure.openloophealth.com/appointments/embed_appt?dietitian_id=3247220&embed_form_id=1816228&form_only=true&primary_color=005F6C'
  : 'https://securestaging.gethealthie.com/appointments/embed_appt?dietitian_id=709153&embed_form_id=897053&form_only=true&primary_color=005F6C';

export const WEIGHTLOSS_FOLLOWUP_FORM_URL = isProductionEnvironment
  ? 'https://secure.openloophealth.com/appointments/embed_appt?dietitian_id=3318205&embed_form_id=1816229&form_only=true&primary_color=005F6C'
  : 'https://securestaging.gethealthie.com/appointments/embed_appt?dietitian_id=649550&embed_form_id=897052&form_only=true&primary_color=005F6C';

export const WL_SYNC_INITIAL_FORM_ID = isProductionEnvironment ? '1816228' : '897053';
export const WL_FOLLOWUP_FORM_ID = isProductionEnvironment ? '1844654' : '897052';

export const WL_ENCOUNTER_FORM_ID = isProductionEnvironment ? '1994441' : '1343543';
export const LABS_CUSTOM_MODULE_ID = isProductionEnvironment ? '25960521' : '11559398';

export const AUTH_COOKIE_NAME = 'auth-token';
export const CHECKOUT_COOKIE_NAME = 'checkout_session';

export const CHECKOUT_EXPIRED_MESSAGE = 'Session is expired';
export const CHECKOUT_NOT_FOUND_MESSAGE = 'Session not found';

export const CHECKOUT_EXPIRED_PARAM = 'checkout_expired';

export const AGILE_SUPPORT_EMAIL = 'agilepatientsupport@openloophealth.com';

export const AGILE_SUPPORT_PHONE = '+15152182464';
