'use client';
import Image from 'next/image';
import { Transition } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';

import { animateOnScroll, scrollToSection } from 'utils';
import howOurProgramWorks from 'assets/images/how-our-program-works-banner.png';
import Button from 'components/Button';

export interface stepProps {
  title: string;
  description: string;
}

interface HowOurProgramWorksProps {
  items: stepProps[];
  buttonText: string;
  buttonRef: React.RefObject<HTMLDivElement>;
  buttonLink?: string;
  title: string;
  description: string;
}

const HowOurProgramWorks = ({
  buttonRef,
  buttonText,
  description,
  items,
  title,
}: HowOurProgramWorksProps) => {
  const delayOptions = ['delay-300', 'delay-500', 'delay-700', 'delay-1000'];
  const [show, setShow] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => animateOnScroll(setShow, sectionRef), []);

  return (
    <div ref={sectionRef} className="py-16 lg:mt-0 lg:pb-24">
      <section className="mx-4 flex max-w-4xl flex-col items-center gap-8 lg:mx-auto">
        <div className="lg:hidden">
          <Image alt="How our programs work banner" src={howOurProgramWorks} />
        </div>
        <div className="mx-4 flex flex-col gap-2">
          <h2 className="text-center text-4xl font-medium lg:text-3xl lg:font-normal">{title}</h2>
          <p className="text-center text-lg text-black-800 lg:mx-20 lg:text-base">{description}</p>
        </div>
        <div className="flex flex-col gap-10 lg:flex-row lg:gap-10">
          <div className="hidden lg:block lg:w-1/2">
            <Image alt="How our programs work banner" src={howOurProgramWorks} />
          </div>
          <div className="flex flex-col gap-10 lg:mt-4 lg:w-1/2 lg:gap-5">
            {items.map((step, index) => {
              const stepDelay = delayOptions[index];

              return (
                <Transition
                  key={`step-${index}`}
                  appear={true}
                  enter={`transition-all duration-500 ${stepDelay}`}
                  enterFrom="translate-y-10 opacity-0"
                  enterTo="translate-y-0 opacity-100"
                  show={show}
                >
                  <div className="flex gap-6 lg:py-5 lg:pr-2">
                    <h4 className="text-7xl text-yellow-900 lg:text-8xl lg:font-semibold">
                      {index + 1}
                    </h4>
                    <div className="flex flex-col gap-2 lg:justify-center">
                      <p className="font-bold text-black-800 lg:text-sm lg:font-semibold">
                        {step.title}
                      </p>
                      <p className="text-sm text-black-800 lg:text-xs">{step.description}</p>
                    </div>
                  </div>
                </Transition>
              );
            })}
          </div>
        </div>
        <div className="flex w-full flex-col lg:items-center">
          <Button
            className="inline-block w-full rounded-md border border-teal-900 bg-arctic-900 px-4 py-2 text-center 
                        text-sm font-bold uppercase tracking-wide text-teal-900 transition-all 
                        ease-in-out hover:-translate-y-0.5 hover:border-2 hover:bg-sky-900 lg:w-1/4 lg:text-center lg:font-semibold"
            variant="primary"
            onClick={() => scrollToSection(buttonRef)}
          >
            {buttonText}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default HowOurProgramWorks;
