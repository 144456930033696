'use client';
import Image from 'next/image';
import { Transition } from '@headlessui/react';
import { useEffect, useState, useRef } from 'react';

import { animateOnScroll, scrollToSection } from 'utils';
import Button from 'components/Button';

export interface Expert {
  name: string;
  title: string;
  headshot: { url: string };
}
interface MeetOurExpertsProps {
  items: Expert[];
  buttonLink?: string;
  buttonRef: React.RefObject<HTMLDivElement>;
  buttonText: string;
  description: string;
  title: string;
}
const MeetOurExperts = ({
  buttonRef,
  buttonText,
  description,
  items,
  title,
}: MeetOurExpertsProps) => {
  const [show, setShow] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => animateOnScroll(setShow, sectionRef), []);

  return (
    <div ref={sectionRef} className="pb-1 pt-16 lg:mt-0 lg:pb-8">
      <section className="mx-4 flex max-w-4xl flex-col items-center gap-6 lg:mx-auto lg:gap-8">
        <div className="flex flex-col gap-6">
          <h2 className="mx-4 text-center text-4xl font-medium lg:text-3xl lg:font-normal">
            {title}
          </h2>
          <p className="text-center text-lg text-black-800 lg:text-base">{description}</p>
        </div>
        <div className="mx-14 flex flex-col gap-6 lg:flex-row lg:gap-10 lg:px-10">
          {items.map((expert, index) => {
            return (
              <Transition
                key={`step-${index}`}
                appear={true}
                enter={`transition-all duration-500 delay-300`}
                enterFrom="translate-y-10 opacity-0"
                enterTo="translate-y-0 opacity-100"
                show={show}
              >
                <div className="flex flex-col gap-6 rounded-lg border border-cyan-900 p-4 lg:h-[260px] lg:py-5">
                  <div className="relative h-36 w-36 overflow-hidden rounded-full lg:h-28 lg:w-28">
                    <Image
                      alt={'Expert Profile Picture'}
                      height={144}
                      src={expert.headshot.url}
                      width={144}
                    />
                  </div>
                  <p className="text-center text-sm font-bold text-teal-900 lg:text-xs lg:font-semibold">
                    {expert.name}, {expert.title}
                  </p>
                </div>
              </Transition>
            );
          })}
        </div>
        <div className="flex w-full flex-col lg:items-center">
          <Button
            className="inline-block w-full rounded-md border border-teal-900 bg-arctic-900 px-4 py-2 text-center 
                        text-sm font-bold uppercase tracking-wide text-teal-900 transition-all 
                        ease-in-out hover:-translate-y-0.5 hover:border-2 hover:bg-sky-900 lg:w-1/4 lg:text-center lg:font-semibold"
            variant="primary"
            onClick={() => scrollToSection(buttonRef)}
          >
            {buttonText}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default MeetOurExperts;
